import type {HTMLAttributeAnchorTarget} from 'react';

import {RevenueValues} from '@/components/shared/BaseLeadForm/fields/constants';
import Button from '@/components/base/elements/Button/Button';
import useSignup from '@/hooks/useSignup';
import {useRetailUnifiedFunnel} from '@/hooks/experiments/useRetailUnifiedFunnel';
import HeadingGroup from '@/components/base/modules/HeadingGroup/HeadingGroup';
import Card from '@/components/base/modules/Card/Card';
import {IconEnum} from '@/enums';
import {useSiteData} from '@/hooks/useSiteData';
import Conversion from '@/components/base/sections/Conversion/Conversion';
import Section from '@/components/base/layouts/Section/Section';
import {twMerge} from '@/stylesheets/twMerge';
import {buttonStyles} from '@/components/base/elements/Button/styles';

import type {ContactModalContent} from './useContactFormLoaderData';

const SALES_PHONE_NUMBER = '1-844-230-0603';

interface ContactFormSuccessProps {
  content: ContactModalContent['content'];
  unifiedContent: ContactModalContent['unifiedContent'];
  signupButton: ContactModalContent['signupButton'];
  annualRevenue: RevenueValues;
  handleDriftClick: () => void;
  signupUrl: string;
}

export function ContactFormSuccess({
  content,
  unifiedContent,
  signupButton,
  annualRevenue,
  handleDriftClick,
  signupUrl,
}: ContactFormSuccessProps) {
  const isGettingStarted = [
    RevenueValues.GETTING_STARTED,
    RevenueValues.BETWEEN_1_250K,
  ].includes(annualRevenue as RevenueValues);

  return isGettingStarted ? (
    <GettingStarted
      content={content}
      signupButton={signupButton}
      signupUrl={signupUrl}
    />
  ) : (
    <CallBackConfirmation
      content={content}
      unifiedContent={unifiedContent}
      handleDriftClick={handleDriftClick}
      signupUrl={signupUrl}
    />
  );
}

function CallBackConfirmation({
  unifiedContent,
  content,
  handleDriftClick,
  signupUrl,
}: Pick<
  ContactFormSuccessProps,
  'content' | 'unifiedContent' | 'handleDriftClick' | 'signupUrl'
>) {
  const {isRetailUnifiedFunnelExperiment} = useRetailUnifiedFunnel();
  const {getUrl} = useSiteData();
  const cards = [
    {icon: IconEnum.ComputerTick, action: {href: signupUrl}},
    {icon: IconEnum.Messages, action: {onClick: handleDriftClick}},
    {
      icon: IconEnum.PosTick,
      action: {href: getUrl('/pos/demo/contact', 'site'), target: '_blank'},
    },
  ];
  const handlePhoneCallClick = () => {
    const telFormattedPhoneNumber = SALES_PHONE_NUMBER.replace(/\s/g, '');
    window.location.href = `tel:${telFormattedPhoneNumber}`;
  };

  /* Retail Unified Funnel - treatment experience */
  if (isRetailUnifiedFunnelExperiment) {
    return (
      <>
        <Section mode="dark" className="pb-2xl pt-0 gap-xl bg-shade-90">
          <HeadingGroup
            headingHtml={unifiedContent.formRevSuccessHeading}
            subheadHtml={unifiedContent.formRevSuccess}
            size="t1"
            mode="dark"
            center
          />
          <HeadingGroup
            headingHtml={unifiedContent.contactSales.heading}
            subheadHtml={unifiedContent.contactSales.content}
            size="t4"
            mode="dark"
            center
          />
          <button
            className={twMerge(
              buttonStyles({
                mode: 'dark',
              }),
              'mx-auto sm:hidden',
            )}
            onClick={handlePhoneCallClick}
          >
            {unifiedContent.contactSales.mobileCta}
          </button>
          <div className="flex flex-wrap justify-center pt-xl gap-6">
            {unifiedContent.cards.map(
              (
                item: ContactModalContent['unifiedContent']['cards'][0],
                idx: number,
              ) => (
                <CardWrapper action={cards[idx].action} key={item.headingHtml}>
                  <Card
                    headingGroup={{
                      headingHtml: `<span class="arrow-animation !no-underline after:group-hover:opacity-100 after:group-hover:translate-x-1">${item.headingHtml}</span>`,
                      subheadHtml: item.subheadHtml,
                    }}
                    icon={{
                      icon: cards[idx].icon,
                      size: 3,
                      className:
                        'bg-lime-10 text-lime-90 w-3xl h-3xl md:p-5 p-md rounded-2xl',
                    }}
                    mode="light"
                    type="outline"
                    size="small"
                    className="group shadow-md max-w-[384px] text-pretty bg-white"
                  />
                </CardWrapper>
              ),
            )}
          </div>
        </Section>
        <Section className="py-2xl bg-lime-10 md:mx-[-1000px] xs:mx-[-32px] xs:px-xl">
          <Conversion
            headingHtml={unifiedContent.conversion.headingHtml}
            buttons={[
              {
                text: unifiedContent.conversion.buttons[0].text,
                href: getUrl(
                  '/support/topic-select/store-management/retail-and-shopify-pos/contact',
                  'help',
                ),
              },
            ]}
          />
        </Section>
      </>
    );
  }

  /* Retail Unified Funnel - control experience */
  return (
    <Section mode="dark" className="gap-xl bg-shade-90">
      <HeadingGroup
        headingHtml={content.formRevSuccessHeading}
        subheadHtml={content.formRevSuccess}
        size="t1"
        mode="dark"
        center
      />
      <HeadingGroup
        headingHtml={content.contactSales.heading}
        subheadHtml={content.contactSales.content}
        size="t4"
        mode="dark"
        center
      />
    </Section>
  );
}

function GettingStarted({
  content,
  signupButton,
  signupUrl,
}: Pick<ContactFormSuccessProps, 'content' | 'signupButton' | 'signupUrl'>) {
  const {signupName} = useSignup();

  return (
    <Section mode="dark" className="gap-xl bg-shade-90">
      <HeadingGroup
        headingHtml={content.formRevNonEligibleHeading}
        subheadHtml={content.formRevNonEligible}
        size="t2"
        mode="dark"
        center
      />
      <div className="mx-auto">
        <Button href={signupUrl} componentName={signupName} mode="dark">
          {signupButton}
        </Button>
      </div>
    </Section>
  );
}

const CardWrapper = ({
  action,
  children,
}: {
  action: {
    href?: string;
    target?: HTMLAttributeAnchorTarget;
    onClick?: () => void;
  };
  children: JSX.Element;
}) => {
  if (action.href) {
    return (
      <a
        href={action.href}
        target={action.target}
        {...(action.target === '_blank' ? {rel: 'noopener noreferrer'} : {})}
      >
        {children}
      </a>
    );
  }
  return <button onClick={action.onClick}>{children}</button>;
};
