import Modal, {ModalColorScheme} from '@/components/shared/Modal/Modal';
import {
  FormSourceTypes,
  DirectMarketingCampaignStatuses,
  FormTypes,
  ModalSizeEnum,
  ProductInterests,
} from '@/enums';
import {removeHash, usePushState} from '@/hooks/usePushState';
import type {Site} from '@data/types';
import {useSiteData} from '@/hooks/useSiteData';

import ContactForm from './ContactForm';
import type {ContactModalContent} from './useContactFormLoaderData';

export default function ContactFormModal(content: ContactModalContent) {
  const {site} = useSiteData();
  const {targetMatched} = usePushState({targetHash: '#contact-sales'});
  const colorScheme = ModalColorScheme.Dark;

  return (
    <Modal
      handleClose={removeHash}
      id="pos-contact-modal"
      isActive={targetMatched}
      modalSize={ModalSizeEnum.Full}
      colorScheme={colorScheme}
    >
      <ContactForm
        {...content}
        colorScheme={colorScheme}
        formSource={FormSourceTypes.Contact}
        directMarketingCampaignId="7018V000001V8WFQA0"
        directMarketingCampaignStatus={
          DirectMarketingCampaignStatuses.FilledOutForm
        }
        formSubmissionEndpoint={getRegistrationFormUrl(site)}
        formType={FormTypes.Contact}
        productInterest={ProductInterests.PosPro}
      />
    </Modal>
  );
}

const getRegistrationFormUrl = (site: Site) => {
  const baseUrl = '/pos/contact';
  return site.pathPrefix ? `/${site.pathPrefix}${baseUrl}` : baseUrl;
};
