import {useLoaderData} from '@remix-run/react';

import type {contactFormLoader} from '../loader.server';

export function useContactFormLoaderData() {
  return (
    useLoaderData() as unknown as Awaited<ReturnType<typeof contactFormLoader>>
  ).contactModalContent;
}

export type ContactModalContent = ReturnType<typeof useContactFormLoaderData>;
