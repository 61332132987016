/* eslint-disable camelcase */
import {useFetcher} from '@remix-run/react';
import {useViewability} from '@shopify/dux';
import cn from 'classnames';
import {ValidatedForm, useIsSubmitting} from 'remix-validated-form';
import {useState} from 'react';
import {cva} from 'class-variance-authority';

import FormButton from '@/components/base/elements/FormButton/FormButton';
import Typography from '@/components/base/elements/Typography/Typography';
import {BaseInput as Input} from '@/components/shared/validatedForm/legacy/BaseInput';
import {ModalColorScheme} from '@/components/shared/Modal/Modal';
import type {
  FormSourceTypes,
  FormTypes,
  DirectMarketingCampaignStatuses,
} from '@/enums';
import useDataLayer from '@/hooks/useDataLayer';
import {useSiteData} from '@/hooks/useSiteData';
import {twMerge} from '@/stylesheets/twMerge';
import type {DriftWindow} from '@/components/shared/Driftbot/types';
import {buttonStyles} from '@/components/base/elements/Button/styles';
import ShopifyLogo from '@/components/base/elements/Logo/ShopifyLogo';
import {
  ShopifyLogoColor,
  ShopifyLogoTheme,
} from '@/components/base/elements/Logo/types';
import AnnualRevenueRangeSelect from '@/components/shared/LeadFormFields/AnnualRevenueRangeSelect';
import {useFormAttributionTracking} from '@/hooks/useFormAttributionTracking';
import LocationsSelect from '@/components/shared/LeadFormFields/LocationsSelect';
import {useRetailUnifiedFunnel} from '@/hooks/experiments/useRetailUnifiedFunnel';
import useSignup from '@/hooks/useSignup';
import {useSignupUrl} from '@/hooks/useSignupUrl';

import type {ContactModalContent} from './useContactFormLoaderData';
import {posContactFormValidator} from './validator';
import {ContactFormSuccess} from './ContactFormSuccess';

interface ContactFormProps {
  colorScheme?: ModalColorScheme;
  formSource: FormSourceTypes;
  formSubmissionEndpoint: string;
  formType: FormTypes;
  productInterest: string;
  directMarketingCampaignId?: string;
  directMarketingCampaignStatus?: DirectMarketingCampaignStatuses;
  wrapperClassName?: string;
}

const InputErrorStyles = {
  errorClass: cva('', {
    variants: {
      mode: {
        light: '',
        dark: 'bg-mandarin-50 shadow-mandarin-50',
      },
    },
  }),
  inputOnErrorClass: cva('', {
    variants: {
      mode: {
        light: '',
        dark: 'shadow-mandarin-50',
      },
    },
  }),
};

interface ContactFormTrackingData {
  form_type: FormTypes;
  page_view_token: string;
  experiment_variation_id?: string;
  product_interest: string;
  primary_product: string;
  source: FormSourceTypes;
  user_token: string;
  session_token: string;
  sign_up_page: string;
  utm_medium: string;
  utm_campaign: string;
  utm_content: string;
  utm_source: string;
  utm_term: string;
  gclid: string;
  direct_marketing_campaign_id?: string;
  direct_marketing_campaign_status: DirectMarketingCampaignStatuses;
  routing_bypass: string;
  // These two properties are for GtmContext compatibility but are not actually used
  emailAddress?: string;
  shopName?: string;
}

export default function ContactForm({
  colorScheme = ModalColorScheme.Light,
  formSource,
  formSubmissionEndpoint,
  formType,
  productInterest,
  directMarketingCampaignId,
  directMarketingCampaignStatus,
  wrapperClassName = '',
  forms,
  submit,
  content,
  unifiedContent,
  signupButton,
}: ContactFormProps & ContactModalContent) {
  const [viewableRef] = useViewability();
  const {getUrl} = useSiteData();
  const dataLayer = useDataLayer();
  const [driftDisplay, setDriftDisplay] = useState(false);
  const {signupUrl} = useSignup();
  const {getSignupURL} = useSignupUrl();

  const handleDriftClick = () => {
    const driftWindow = window as DriftWindow;
    driftWindow.drift.api.openChat();
    setDriftDisplay(true);
  };

  const errorClassName = InputErrorStyles.errorClass({mode: colorScheme});
  const inputOnErrorClassName = InputErrorStyles.inputOnErrorClass({
    mode: colorScheme,
  });

  const fetcher = useFetcher<any>();
  const submitResponse = fetcher.data?.status;
  const annualRevenue =
    fetcher.data?.formHash['forms_contact[annual_offline_revenue_range]'];
  const email = fetcher.data?.formHash['forms_contact[email]'];

  const signupUrlWithEmail = getSignupURL({
    url: signupUrl,
    email: email,
  }).toString();

  const isSubmitting = useIsSubmitting(formType);

  const attributionProps = {
    form_type: formType,
    experiment_variation_id: dataLayer.experimentVariationId,
    product_interest: productInterest,
    primaryProduct: productInterest,
    source: formSource,
    direct_marketing_campaign_id: directMarketingCampaignId,
    direct_marketing_campaign_status: directMarketingCampaignStatus,
  };
  const {submitHandler, trackingProperties} = useFormAttributionTracking({
    attributionProps,
    eventCategory: `${productInterest}_Resource`,
    submitResponse,
  });

  const {isRetailUnifiedFunnelExperiment} = useRetailUnifiedFunnel();

  const conditionalContent = isRetailUnifiedFunnelExperiment
    ? {
        formHeading: unifiedContent.formHeading,
        formCopy: unifiedContent.formCopy,
      }
    : {
        formHeading: content.formHeading,
        formCopy: content.formCopy,
      };

  return submitResponse === 'success' ? (
    <ContactFormSuccess
      content={content}
      unifiedContent={unifiedContent}
      signupButton={signupButton}
      signupUrl={signupUrlWithEmail}
      annualRevenue={annualRevenue}
      handleDriftClick={handleDriftClick}
    />
  ) : (
    <>
      <style>
        {driftDisplay
          ? `#drift-frame-controller { display: block; }`
          : `#drift-frame-controller { display: none; }`}
      </style>
      <section
        className={twMerge('pb-16', wrapperClassName)}
        data-component-name="lead-registration-form"
        data-component-extra-form-type={trackingProperties.form_type}
        data-component-extra-source={trackingProperties.source}
        data-component-extra-primary-product={trackingProperties.primaryProduct}
        data-component-extra-sign-up-page={trackingProperties.sign_up_page}
        // wait for trackingData to settle
        ref={trackingProperties.form_type ? viewableRef : undefined}
      >
        <div className="flex flex-col sm:flex-row gap-10">
          <div className="sm:basis-1/2">
            <a href={getUrl('/pos')} data-component-name="logo-contact-form">
              <ShopifyLogo
                className="absolute left-0 -top-24"
                logoTheme={ShopifyLogoTheme.White}
                logoWithText={true}
                textColor={ShopifyLogoColor.White}
                height="36"
              />
            </a>
            <Typography as="h2" isRichtext={true} size="t1" className="mb-lg">
              {conditionalContent.formHeading}
            </Typography>
            <Typography isRichtext>{conditionalContent.formCopy}</Typography>
          </div>
          <div className="sm:basis-1/2">
            <ValidatedForm
              validator={posContactFormValidator}
              method="post"
              acceptCharset="UTF-8"
              id={formType}
              onSubmit={submitHandler}
              fetcher={fetcher}
              action={formSubmissionEndpoint}
              noValidate
            >
              <Input
                key="name"
                id="name"
                placeholder={forms.name.label}
                label={forms.name.label}
                type="text"
                errorClassName={errorClassName}
                inputOnErrorClassName={inputOnErrorClassName}
              />
              <Input
                key="email"
                id="email"
                placeholder={forms.email.label}
                label={forms.email.label}
                type="email"
                errorClassName={errorClassName}
                inputOnErrorClassName={inputOnErrorClassName}
              />
              <Input
                key="phone"
                id="phone"
                placeholder={forms.phone.label}
                label={forms.phone.label}
                type="tel"
                errorClassName={errorClassName}
                inputOnErrorClassName={inputOnErrorClassName}
              />
              <Input
                key="company"
                id="company"
                placeholder={forms.company.label}
                label={forms.company.label}
                type="text"
                errorClassName={errorClassName}
                inputOnErrorClassName={inputOnErrorClassName}
              />
              <AnnualRevenueRangeSelect
                type="offline"
                legacyField
                selectProps={{
                  errorClassName: errorClassName,
                  inputOnErrorClassName: inputOnErrorClassName,
                }}
              />
              <LocationsSelect
                selectProps={{
                  errorClassName: errorClassName,
                  inputOnErrorClassName: inputOnErrorClassName,
                }}
                legacyField
              />
              {Object.keys(trackingProperties).map((key) => (
                <input
                  type="hidden"
                  name={key}
                  key={key}
                  value={
                    trackingProperties[key as keyof ContactFormTrackingData]
                  }
                />
              ))}
              <div
                id="marketing_communication_disclaimer_text"
                className={cn('flex items-top [&_label]:text-sm', {
                  '[&_label]:text-white': colorScheme === ModalColorScheme.Dark,
                  '[&_label]:text-black':
                    colorScheme === ModalColorScheme.Light,
                })}
              >
                <Input
                  key="marketing_communication_consent"
                  id="marketing_communication_consent"
                  name="marketing_communication_consent"
                  label={forms.marketingCommunicationConsent.label}
                  type="checkbox"
                  className="w-4 mt-sm mr-sm inline-block"
                  errorClassName="bg-mandarin-50 shadow-mandarin-50"
                  inputOnErrorClassName="shadow-mandarin-50"
                />
              </div>
              <div className="flex flex-end">
                <FormButton
                  type="submit"
                  className="grow md:grow-0 mt-sm bg-lime-10"
                  componentName="registration-form-submit"
                  mode={colorScheme}
                  aria-describedby="registration-disclaimerText"
                  disabled={isSubmitting}
                >
                  {isSubmitting ? submit.label_submitting : submit.label}
                </FormButton>
              </div>
            </ValidatedForm>
            {!isRetailUnifiedFunnelExperiment && (
              <div className="mt-10 py-10 border-t-[1px] flex flex-col gap-lg">
                <Typography as="h3" isRichtext={false} size="t6">
                  {content.chat.heading}
                </Typography>
                <button
                  className={twMerge(
                    buttonStyles({
                      intent: 'secondary',
                      mode: 'dark',
                      size: 'large',
                      border: true,
                    }),
                    'self-start',
                  )}
                  onClick={handleDriftClick}
                >
                  {content.chat.button}
                </button>
                <Typography isRichtext size="body-sm">
                  {content.chat.content}
                </Typography>
              </div>
            )}
          </div>
        </div>
      </section>
    </>
  );
}
