import {withZod} from '@remix-validated-form/with-zod';
import {z} from 'zod';

import {annualOfflineRevenueRangeValidation} from '@/components/shared/LeadFormFields/AnnualRevenueRangeSelect';
import {locationsValidation} from '@/components/shared/LeadFormFields/LocationsSelect';

/* eslint-disable camelcase */
const schema = {
  name: z.string().min(1, {message: 'forms:webinar.name.error'}),
  email: z
    .string()
    .min(1, {message: 'forms:webinar.email.error'})
    .email('forms:webinar.email.error'),
  phone: z.string().min(1, {message: 'forms:webinar.phone.error'}),
  company: z.string().min(1, {message: 'forms:webinar.company.error'}),
  marketing_communication_consent: z.string().optional().default('off'),
  ...annualOfflineRevenueRangeValidation,
  ...locationsValidation,
};
export const posContactFormValidator = withZod(z.object(schema));
